import React, { useEffect, useRef, useState } from 'react'
import { Table, Pagination, Spin, Modal } from 'antd'
import { connect } from 'react-redux'
import { debouncedGetCustomers, setModal } from '../../store/job/actions.js'
import { dateFormat } from '../../helpers/stringFormat.js'
import Customer from '../../components/Customer.js'
import moment from 'moment'
import NewCustomer from '../../components/modals/NewCustomer.js'
import { initializePusher, unbindPusher } from '../../pusherManager.js'

const CustomerList = (props) => {
    const { debouncedGetCustomers, customers } = props
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(localStorage?.getItem("search_customer") ?? "")
    const [sort, setSort] = useState({})
    const [modal, setModal] = useState(false)
    const [customerData, setCustomerData] = useState(null)
    const inputRef = useRef()

    const columns = [
        {
            title: 'Customer Name',
            key: 'name',
            fixed: 'left',
            sorter: {
                compare: (a, b) => (a?.firstname || "").localeCompare(b.firstname || ""),
                multiple: 0
            },
            defaultSortOrder: 'name' in sort ? sort.name : '',
            render: record => <a className="table_anchor">{record?.firstname} {record?.lastname}</a>
        },
        {
            title: 'Company',
            key: 'company',
            sorter: {
                compare: (a, b) => (a.helios_user_setting?.company_name || "").localeCompare(b.helios_user_setting?.company_name || ""),
                multiple: 2
            },
            defaultSortOrder: 'company' in sort ? sort.company : '',
            render: record => <span>{record?.helios_user_setting?.company_name}</span>
        },
        {
            title: 'Phone',
            key: 'phone',
            render: record => <span>{record?.helios_user_setting?.phone_number}</span>
        },
        {
            title: 'Email',
            key: 'email',
            render: record => <span>{record?.email}</span>
        },
        {
            title: 'Address',
            key: 'address',
            render: record => <span>{record?.address}</span>
        },
        {
            title: 'City',
            key: 'city',
            sorter: {
                compare: (a, b) => (a.city || "").localeCompare(b.city || ""),
                multiple: 3
            },
            defaultSortOrder: 'city' in sort ? sort.city : '',
            render: record => <span>{record?.city}</span>
        },
        {
            title: 'State',
            key: 'state',
            sorter: {
                compare: (a, b) => (a.state || "").localeCompare(b.state || ""),
                multiple: 4
            },
            defaultSortOrder: 'state' in sort ? sort.state : '',
            render: record => <span>{record?.state}</span>
        },
        {
            title: 'Zip',
            key: 'zip',
            sorter: {
                compare: (a, b) => (a.zipcode || "").localeCompare(b.zipcode || ""),
                multiple: 5
            },
            defaultSortOrder: 'zip' in sort ? sort.zip : '',
            render: record => <span>{record?.zipcode}</span>
        },
        {
            title: 'Date Added',
            key: 'date_added',
            sorter: {
                compare: (a, b) => (a.created_at || "").localeCompare(b.created_at || ""),
                multiple: 6
            },
            defaultSortOrder: 'date_added' in sort ? sort.name : 'descend',
            render: record => <span>{dateFormat(record?.created_at, 'DATE_ADDED')}</span>
        }
    ]

    useEffect(() => {
        initializePusher("organizations", "choose-event", async (data) => {
            if (data?.orgId) {
                getCustomers()
            }
        });

        return () => {
            unbindPusher("organizations", "choose-event")
        }
    }, [])
    
    useEffect(() => {
        const init = async () => {
            var sortData = await localStorage.getItem("customerSort")
            if (sortData) {
                sortData = await JSON.parse(sortData)
                await setSort(sortData)
            }
        }
        init()
    }, [])

    useEffect(() => {
        getCustomers()
    }, [currentPage])

    useEffect(() => {
        localStorage.setItem("search_customer", search)
        if (search?.length >= 3 || search?.length === 0) {
            getCustomers()
        }
    }, [search])

    function onChangeSearch(e) {
        setSearch(e.target.value)
    }

    function getCustomers() {
        var query = ''
        if (search?.length >= 3)
            query = `${query}?page=1&search=${search}` // send page number 1 in search
        else 
            query = `${query}?page=${currentPage}`
        debouncedGetCustomers(query)
    }

    async function exportCSV() {
        const data = await customers?.data?.map((item) => {
            return ({
                "Customer Name": `${item.firstname} ${item.lastname}`,
                "Company": item?.helios_user_setting?.company_name,
                "Phone": item?.helios_user_setting?.phone_number,
                "Email": item?.email,
                "Address": item?.address,
                "City": item.city,
                "State": item.state,
                "Zip": item.zipcode,
                "Date Added": moment(item?.created_at).format("MMM DD Y")
            })
        })

        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(',')); // Add headers

        data.forEach((row) => {
            const values = headers.map((header) => row[header]);
            csvRows.push(values.join(','));
        });

        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'customers.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async function onChangeTable(pagination, filters, sorter, extra) {
        if (sorter) {
            var temp = {}
            if (sorter?.length > 0) {
                sorter.forEach((row) => {
                    temp = ({ ...temp, [row.columnKey]: row.order })
                })
                setSort(temp)
                localStorage.setItem("customerSort", JSON.stringify(temp))
            } else if (Object.keys(sorter)?.length > 0) {
                temp = ({ ...temp, [sorter.columnKey]: sorter.order })

            }
            setSort(temp)
            localStorage.setItem("customerSort", JSON.stringify(temp))
        }
    }

    return (
        <div className="customer">
            <div className="header pb-3">
                <h5 className="title">Customer List</h5>
                <Customer>
                    <div className='btn-add-customer'>
                        <svg className="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.83333 5.54167V1.16955C5.83333 0.523626 6.35117 0 7 0C7.64433 0 8.16667 0.531721 8.16667 1.16955V5.54167H12.8304C13.4764 5.54167 14 6.05951 14 6.70833C14 7.35267 13.4683 7.875 12.8304 7.875H8.16667V12.8304C8.16667 13.4764 7.64882 14 7 14C6.35567 14 5.83333 13.4683 5.83333 12.8304V7.875H1.16955C0.523626 7.875 0 7.35716 0 6.70833C0 6.064 0.531721 5.54167 1.16955 5.54167H5.83333Z" /></svg>
                        Add New Customer
                    </div>
                </Customer>
            </div>

            <Spin spinning={customers?.loading}>
                <div className='customer-content'>
                    <div className='search-box'>
                        <div className='search-text'>
                            <img style={{ display: inputRef?.current === document.activeElement && search !== "" ? 'none' : 'flex' }} className="search-icon" src={require('../../assets/images/search.svg').default} alt='search' />
                            <div style={{ display: inputRef?.current === document.activeElement && search !== "" ? 'flex' : 'none' }} onClick={() => setSearch("")}>
                                <img className="close-icon" src={require('../../assets/images/close_fill.svg').default} alt='close' />
                            </div>
                            <input
                                ref={inputRef}
                                className='input'
                                placeholder='Search for anything in Customers...   customer name or company ..'
                                onChange={onChangeSearch}
                                value={search}
                            />
                            <div style={{ display: inputRef?.current === document.activeElement && search !== "" ? 'flex' : 'none' }} className='focus-line' />
                        </div>
                        {Object.keys(customers?.data)?.length > 0 &&
                            <div className='btn-export' onClick={() => exportCSV()}>
                                <img className="export-icon" src={require('../../assets/images/Upload.svg').default} alt='close' />
                                <span className='export-text' >EXPORT CSV</span>
                            </div>
                        }
                    </div>

                    <Table
                        key={Object.keys(customers.data)?.length > 0 ? `customers_${customers?.data?.length}` : 'customers_0'}
                        className='w-100'
                        columns={columns}
                        dataSource={Object.keys(customers?.data)?.length > 0 ? customers?.data : []}
                        pagination={false}
                        onChange={onChangeTable}
                        rowKey={obj => obj.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: async event => {
                                    await setCustomerData(record)
                                    await props.setOpenModal(true)
                                    await setModal(true)
                                }
                            };
                        }}
                    />

                    {customers?.total > 0 &&
                        <div className='footer' >
                            <button
                                className='first-last'
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage(1)}>First</button>
                            <div className='flex gap-3'>
                                <Pagination
                                    total={customers?.total}
                                    current={currentPage}
                                    defaultPageSize={customers?.per_page}
                                    onChange={(page) => setCurrentPage(page)} />
                            </div>
                            <button
                                class="first-last"
                                disabled={currentPage === customers?.last_page}
                                onClick={() => setCurrentPage(customers?.last_page)}>Last</button>
                        </div>
                    }
                </div>
            </Spin>

            <Modal key={customerData?.id} title={customerData ? "Edit Customer" : "Add New Customer"} closable={false} open={modal} footer={null} width={750} >
                <NewCustomer isModalOpen={modal} closeModal={() => setModal(false)} customerData={customerData} isEdit={customerData ? Object.keys(customerData)?.length > 0 : false} />
            </Modal>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        customers: state.JobReducer.customers
    }
}

const mapDispatchToProps = (dispatch) => ({
    debouncedGetCustomers: (query) => debouncedGetCustomers(dispatch, query),
    setOpenModal: (payload) => dispatch(setModal(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList)