import React, { useState, useEffect } from 'react';
import { OutlinedButton, FilledButton, FilledLightButton, FilledBrightButton, ApiSpin } from '@oneblinktech-org/helios-base'
import {useForm, Controller} from 'react-hook-form'
import { connect } from 'react-redux'
import { Switch, Select, Spin } from 'antd'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'
import { plus, save } from '../../fakeData/svgFiles.js'
import Block from '../../components/common/block'
import ExpandableTable from '../../components/tables/expandableTable'
import MyShopInformation from '../../components/user/myShopInformation'
import states from '../../fakeData/state'
import {
    debouncedGetProfile,
    debouncedUpdateProfile
} from '../../store/user/actions'
import { dateFormat } from '../../helpers/stringFormat.js'
import { PhoneInputHF } from '../../components/common/masked-hook-form-inputs.js'
import { Prompt } from 'react-router-dom';
import {useDocumentTitle} from '../../components/useDocumentTitle.js';
import { addUserInOrg, getOrgUsers } from '../../../src/services/user';
import { getCookie, openNotification, ROLES, SUPPORT_URL } from '../../helpers/globals.js';
import { initializePusher, unbindPusher } from '../../pusherManager.js';
import AutoSelectOnTab from '../../components/AutoSelectOnTab.js';

const { Option } = Select

const Profile = (props) => {

    useDocumentTitle("Helios - Profile")

    const columns = [
        {
            name: 'Name',
            fixed: 'left',
            align: 'left',
            sorter: false,
            cell: record => 
                <a className={(!record.is_active ? "inactive" : "") + " table_anchor d-flex flex-row justify-content-start align-items-center"}>
                    <div className="profile-user-avatar">
                        {
                            (typeof record?.helios_user_setting?.avatar !== 'undefined' && record?.helios_user_setting?.avatar != null) ?
                            <img alt="" src={record?.helios_user_setting?.avatar} />
                            :
                            <img alt="" src={require('../../assets/images/skel-user.png').default} />
                        }
                        <div style={{
                            backgroundColor: record.is_active ? 'rgba(95, 221, 151, 1)' : 'rgba(244, 99, 99, 1)'
                        }} className="mark-green" />
                    </div>
                    {
                        record.firstname + ' ' + record.lastname
                    }
                </a>,
        },
        {
            name: 'E-mail',
            align: 'left',
            sorter: false,
            cell: record => <span className={(!record.is_active ? "inactive" : "")}>{record.email}</span>,
        },
        {
            name: 'Role',
            align: 'left',
            sorter: false,
            cell: record => <span className={(!record.is_active ? "inactive" : "")}>{record.userType && record.userType.name}</span>,
        },
        {
            name: 'Status',
            align: 'left',
            cell: record => 
                <div 
                    className={(!record.is_active ? "inactive" : "") + " job_status"}
                    style={{ backgroundColor: record.is_active ? 'rgba(95, 221, 151, 1)' : 'rgba(244, 99, 99, 1)' }}>
                </div>,
        }
    ]

    const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) === true : true)
    const [usersToggle, setUsersToggle] = useState(localStorage.getItem('usersToggle') ? JSON.parse(localStorage.getItem('usersToggle')) === true : true)
    const [saveSuccessTXT, setSaveSuccessTXT] = useState("")
    const [organization, SetOrganization] = useState({})
    const [usersInOrg, SetUsersInOrg] = useState([])
    const [statusColumns, setStatusColumns] = useState({})
    const [userFToogle, setUserFToogle] = useState(false)
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        is_active: true
    })
    const [saveLoading, setLoading] = useState(false)
    
    const [isError, setISError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)
    const { register, handleSubmit, getValues, setValue, reset, watch, control,trigger, formState: { errors, dirtyFields } } = useForm()
    const orgId = getCookie('active_organization_id')

    useEffect(() => {
        initializePusher("organizations", "choose-event", (data) => {
            if (data?.orgId?.toString() === orgId?.toString()) {
                props.debouncedGetProfile();
                callOrgUsers();
            }
            if (data.event === "selected" && data?.orgId) {
                props.debouncedGetProfile();
                callOrgUsers();
            }
        })

        return () => {
           unbindPusher("organizations", "choose-event") 
        }
    },[orgId, props])

    const callOrgUsers = () => {
        getOrgUsers().then(response => {
            SetUsersInOrg(response.data)
        });
    }

    useEffect(() => {
        let isMounted = true
        if(isMounted && Object.keys(props.user.data).length > 0) {
            if(Object.keys(props.user.data).length > 0) {
                if((props.user.data.organizations).length > 0) {
                    SetOrganization(props.user.data.organizations[0])
                }

                reset({
                    name:  [props?.user?.data?.firstname, props?.user?.data?.lastname].filter(Boolean).join(' '),
                    email: props?.user?.data?.email ?? "",
                    address: props?.user?.data?.address ?? "",
                    city: props?.user?.data?.city ?? "",
                    zipcode: props?.user?.data?.zipcode ?? "",
                    phone_number: props?.user?.data?.helios_user_setting?.phone_number ?? "",
                    state: props?.user?.data?.state ?? "",
                    pAvatar: props?.user?.data?.helios_user_setting?.avatar ?? "",
                    role: props?.user?.data?.type == null ? "" : props?.user?.data?.type?.name,
                    shopName:  props?.user?.data?.organizations?.[0]?.['name'] ?? "",
                    shopAddr: props?.user?.data?.organizations?.[0]?.['address'] ?? "",
                    shopState: props?.user?.data?.organizations?.[0]?.['state'] ?? "",
                    shopZip: props?.user?.data?.organizations?.[0]?.['zipcode'] ?? "",
                    shopCity: props?.user?.data?.organizations?.[0]?.['city'] ?? "",
                    shopPhone: props?.user?.data?.organizations?.[0]?.['phone'] ?? "",
                })
                

            }
        } else {
            props.debouncedGetProfile();
        }
        return () => {
            isMounted = false
        }
    }, [props.user.data])

    useEffect(() => {
        setIsSuccess(props.user.isSuccess)
        setISError(props.user.isSuccess === false)
        setErrorMsg(props.user.erros)
    }, [props.user.isUpdating])

    const watchAllFields = watch()

    const resetData = () => {

        if (Object.keys(dirtyFields).length > 0 && window.confirm("Changed you made may not be saved.")) {
            reset({
                name: [props.user.data.firstname, props.user.data.lastname].filter(Boolean).join(' '),
                email: props.user.data.email ?? "",
                phone_number: props.user.data?.helios_user_setting?.phone_number ?? "",
                address: props.user.data.address ?? "",
                city: props.user.data.city ?? "",
                zipcode: props.user.data.zipcode ?? "",
                state: props.user.data.state ?? "",
                pAvatar: props.user.data?.helios_user_setting?.avatar ?? "",
                role: props.user.data.type == null ? "" : props.user.data.type.name
            })
        }
    }

    window.onbeforeunload = function(e) {
        if (Object.keys(dirtyFields).length > 0) {
            return "Do you want to exit this page?";
        }
    }

    const editProfile = (data) => {
        let form = new FormData()
        if(data.pAvatar.length == 1) {
            form.append('avatar', data.pAvatar[0], (data.pAvatar[0]).name)
        }

        let sName = data.name.split(' ')
        
        form.append('firstname', sName[0])
        form.append('lastname', sName[1])
        form.append('email', data.email)
        form.append('phone_number', data.phone_number)
        form.append('address', data.address)
        form.append('city', data.city)
        form.append('zipcode', data.zipcode)
        form.append('state', data.state)
        form.append('statusColumns', JSON.stringify(statusColumns))
        form.append('shopName', data.shopName)
        form.append('shopPhone', data.shopPhone)
        form.append('shopState', data.shopState)
        form.append('shopAddr', data.shopAddr)
        form.append('shopCity', data.shopCity)
        form.append('shopZip', data.shopZip)
        props.debouncedUpdateProfile({form: form})
    }

    const onSetCards = (cards, cardOrder) => {
        setStatusColumns({
            'cards': cards,
            'cardOrder': cardOrder
        })
    }

    const addNewUserInOrg = async () => {
        if (newUser.name === "" || newUser.e === "") {
            return;
        }

        if (usersInOrg.some(item => item.email === newUser.email) || newUser.email === getValues('email')) {
            openNotification('Alert!', 'This user already has access.')
            return;
        }

        const nameParts = newUser.name.trim().split(" ");
        let firstname = nameParts[0];
        let lastname = "";
        
        if (nameParts.length > 1) {
            lastname = nameParts.slice(1).join(" ");
        }
        
        let newUserForm = {
            ...newUser,
            firstname: firstname,
            lastname: lastname,
            is_active: newUser.is_active ? 1 : 0,
            role_id: ROLES.Subordinate
        }        

        await setLoading(true)
        await addUserInOrg(newUserForm).then(res => {
            setIsSuccess(true)
            if (res.data && res.status === 200) {
                getOrgUsers()
                    .then(async response => {
                        await SetUsersInOrg(response.data)
                        await setUserFToogle(false)
                        await setLoading(false)
                    }).catch(() => {
                        setUserFToogle(false)
                        setLoading(false)
                    });
            }
        }).catch(error => {
            setISError(true)
            setErrorMsg(error?.message ? error?.message : 'Something went wrong, please try again later or contact support.')
            console.log(error)
            setLoading(false)
        })
        await setNewUser({
            name: "",
            email: "",
            is_active: true
        })
    }

    const handleTabPress = (option) => {
        if (option) {
            setValue('state', option,  { shouldDirty: true });
            trigger('state')
        }
    }
    
    return (
        <Spin spinning={props.user.loading}>
            <Prompt
                when={Object.keys(dirtyFields).length > 0}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <ApiSpin loading={saveLoading || props.user.isUpdating} error={isError} success={isSuccess} error_txt={errorMsg} support_url={SUPPORT_URL} />
            <form onSubmit={handleSubmit(editProfile)} autoComplete="off">
                <div className='d-flex flex-column user-edit'>
                    <div className='header pb-3'>
                        <h6 className='title'>Settings</h6>
                        <div className='action'>
                            {
                                Object.keys(dirtyFields).length > 0
                                    ? <span className='text-danger mr-3'>You have unsaved changes</span>
                                    : saveSuccessTXT != "" &&
                                        <span className='text-success mr-3 '>{saveSuccessTXT}</span>
                            }
                                <FilledButton
                                    title='Cancel'
                                    clickEvent={(e) => resetData()}
                                />
                                <FilledBrightButton
                                    svgFile={save}
                                    title='Save'
                                    type="submit"
                                    clickEvent={(e) => {}}
                                />
                        </div>
                    </div>
                    <div className='full-height d-flex flex-column justify-content-start align-items-center'>
                        <div className='w-100 profile-header py-1 py-md-3 px-2 px-md-5 d-flex flex-column flex-md-row justify-content-between align-items-center'>
                            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                                <label htmlFor='file_uploader_edit' className='file_uploader' style={{borderRadius: '0'}}>
                                    <input
                                        type='file' 
                                        id='file_uploader_edit' 
                                        className='d-none' 
                                        accept='image/.jpeg, .png, .jpg, .svg'
                                        name="pAvatar"
                                        {...register('pAvatar')}
                                    />
                                    {
                                        getValues('pAvatar') != "" && getValues('pAvatar') != null && getValues('pAvatar').length > 1 ?
                                            <img alt='' src={getValues('pAvatar')} />
                                            : (
                                                getValues('pAvatar') && getValues('pAvatar').length > 0 &&
                                                <img alt='' src={getValues('pAvatar').length > 0 ? URL.createObjectURL(getValues('pAvatar')[0]) : ''} />
                                        )

                                    }
                                    {errors.pAvatar && <small className='text-danger error-text'>This field is required</small>}
                                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'relative', top: '-22px', left: '55px'}}>
                                    <g filter="url(#filter0_dd_5068_47932)">
                                      <circle cx="19" cy="17" r="14.5" fill="#1658AC" stroke="#F2F7FE"/>
                                      <path d="M25.5882 23H12.4118C11.6118 23 11 22.3943 11 21.6022V13.2151C11 12.4229 11.6118 11.8172 12.4118 11.8172H15.2353C15.3294 11.8172 15.4706 11.724 15.5176 11.6774L16.0353 10.6523C16.2235 10.2796 16.6941 10 17.1176 10H20.8824C21.3059 10 21.7765 10.2796 21.9647 10.6523L22.4824 11.6774C22.5294 11.7706 22.6706 11.8172 22.7647 11.8172H25.5882C26.3882 11.8172 27 12.4229 27 13.2151V21.6022C27 22.3943 26.3882 23 25.5882 23V23ZM12.4118 12.7491C12.1294 12.7491 11.9412 12.9355 11.9412 13.2151V21.6022C11.9412 21.8817 12.1294 22.0681 12.4118 22.0681H25.5882C25.8706 22.0681 26.0588 21.8817 26.0588 21.6022V13.2151C26.0588 12.9355 25.8706 12.7491 25.5882 12.7491H22.7647C22.3412 12.7491 21.8706 12.4695 21.6824 12.0968L21.1647 11.0717C21.1176 10.9785 20.9765 10.8853 20.8824 10.8853H17.1176C17.0235 10.8853 16.8824 10.9785 16.8353 11.0251L16.3176 12.0502C16.1294 12.4695 15.6588 12.7491 15.2353 12.7491H12.4118V12.7491Z" fill="white"/>
                                      <path d="M18.9999 21.1361C16.647 21.1361 14.7646 19.2723 14.7646 16.9426C14.7646 14.6128 16.647 12.749 18.9999 12.749C21.3529 12.749 23.2352 14.6128 23.2352 16.9426C23.2352 19.2723 21.3529 21.1361 18.9999 21.1361ZM18.9999 13.6809C17.1646 13.6809 15.7058 15.1254 15.7058 16.9426C15.7058 18.7598 17.1646 20.2042 18.9999 20.2042C20.8352 20.2042 22.2941 18.7598 22.2941 16.9426C22.2941 15.1254 20.8352 13.6809 18.9999 13.6809Z" fill="white"/>
                                      <path d="M23.7059 14.1467C23.9658 14.1467 24.1765 13.9381 24.1765 13.6808C24.1765 13.4235 23.9658 13.2148 23.7059 13.2148C23.446 13.2148 23.2354 13.4235 23.2354 13.6808C23.2354 13.9381 23.446 14.1467 23.7059 14.1467Z" fill="white"/>
                                      <path d="M12.8823 10.8853H14.7647V11.3512H12.8823V10.8853ZM19 15.0788V14.6129C17.6823 14.6129 16.647 15.6379 16.647 16.9426H17.1176C17.1176 15.9175 17.9647 15.0788 19 15.0788Z" fill="white"/>
                                    </g>
                                    <defs>
                                      <filter id="filter0_dd_5068_47932" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="2"/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5068_47932"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="0.5"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"/>
                                        <feBlend mode="normal" in2="effect1_dropShadow_5068_47932" result="effect2_dropShadow_5068_47932"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5068_47932" result="shape"/>
                                      </filter>
                                    </defs>
                                  </svg>

                                </label>
                                <div className='user-contact-view ml-1 ml-md-4'>
                                    <p className='m-0 username'>{getValues('name')}<span className='userid ml-1 ml-md-4'>USER ID # {props.user.data.id}</span></p>
                                    <p className='m-0 userrole mb-2'>Owner | {props.user.data?.helios_user_setting?.company_name}</p>
                                    <p className='m-0 useremail'><img alt='' src={require('../../assets/images/email.svg').default} className='mr-2' />{getValues('email')}</p>
                                    <p className='m-0 userphone'><img alt='' src={require('../../assets/images/phone.svg').default} className='mr-2' />{getValues('phone_number')}</p>
                                </div>
                            </div>
                            <div className=''>
                                <p className='m-0 lastlogin'>Last Login</p>
                                <p className='m-0 lastlogin userrole mb-2'>
                                    {dateFormat((props.user.data.last_login + " UTC"), 'USER_LOGIN_DATE_FORMAT')}</p>
                                <div className='d-flex flex-row align-items-center'>
                                    <OutlinedButton title='Send Password Reset' className='resetpass ml-3'/>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 mt-2 profile-body py-3 px-3 pl-30'>
                            <Block
                                title='Your Information'
                                isOpen={userInfo}
                                onToggle={() => {
                                    let userInfoV = !userInfo
                                    localStorage.setItem('userInfo', userInfoV)
                                    setUserInfo(userInfoV)
                                }}
                            >
                                <div className='content row '>
                                    <div className='col-12 col-md-3 edit-wrap mobile-mt'>
                                        <label>Name</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                autoComplete="name"
                                                {...register('name', { 
                                                    required: true, 
                                                })}
                                            />
                                            <img className={Object.keys(dirtyFields).includes('name') && dirtyFields['name'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.name && <small className='text-danger error-text'>This field is required</small>}
                                    </div>
                                    <div className='col-12 col-md-3 offset-md-1 edit-wrap mobile-mt'>
                                        <label>Email</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                autoComplete="email"
                                                {...register('email', { 
                                                    required: true, 
                                                })}
                                            />
                                            <img className={Object.keys(dirtyFields).includes('email') && dirtyFields['email'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.email && <small className='text-danger error-text'>This field is required</small>}
                                    </div>
                                    <div className='col-12 col-md-3 offset-md-2 edit-wrap mobile-mt'>
                                        <label>Phone</label>
                                        <div>
                                            <PhoneInputHF
                                                name="phone_number"
                                                control={control}
                                                mask="_"
                                                format="+1 (###) ### ####"
                                            />
                                            <img className={Object.keys(dirtyFields).includes('phone_number') && dirtyFields['phone_number'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.phone_number && <small className='text-danger error-text'>{errors.phone_number?.message}</small>}
                                    </div>
                                </div>
                                <div className='content row mt-4 '>
                                    <div className='col-12 col-md-4 edit-wrap mobile-mt'>
                                        <label>Address</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                autoComplete="address"
                                                {...register('address', { 
                                                    required: true, 
                                                })}
                                            />
                                            <img className={Object.keys(dirtyFields).includes('address') && dirtyFields['address'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.address && <small className='text-danger error-text'>This field is required</small>}
                                    </div>
                                    <div className='col-12 col-md-2 position-relative edit-wrap mobile-mt'>
                                        <label>City</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                autoComplete="city"
                                                {...register('city', { 
                                                    required: true, 
                                                })}
                                            />
                                            <img className={Object.keys(dirtyFields).includes('city') && dirtyFields['city'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.city && <small className='text-danger error-text'>This field is required</small>}
                                    </div>
                                    <div className='col-12 col-md-3 position-relative edit-wrap mobile-mt'>
                                        <label>State</label>
                                        <Controller
                                            render={({ field: { onChange, value, name, ref } }) => {
                                                return (
                                                    <>
                                                        <AutoSelectOnTab
                                                            value={value}
                                                            placeholder='Choose state'
                                                            options={states?.map((item)=> {return {label: item.name, value: item.name} })}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                            }}
                                                            handleTabPress={handleTabPress}
                                                            style={{ width:'100%' }}
                                                        />
                                                    </>
                                                )
                                            }}
                                            control={control}
                                            name='state'
                                        />
                                        {errors.state && <small className='text-danger error-text'>This field is required</small>}
                                    </div>
                                    <div className='col-12 col-md-3 edit-wrap mobile-mt'>
                                        <label>zip</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="zipcode"
                                                id="zipcode"
                                                autoComplete="zipcode"
                                                {...register('zipcode', { 
                                                    required: true,
                                                    pattern: {
                                                        value: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/,
                                                        message: "Invalid zip code"
                                                    }
                                                })}
                                            />
                                            <img className={Object.keys(dirtyFields).includes('zipcode') && dirtyFields['zipcode'] == true ? 'edited' : ''} src={require('../../assets/images/pencil.svg').default} alt='' />
                                        </div>
                                        {errors.zipcode && <small className='text-danger error-text'>{errors.zipcode?.message}</small>}
                                    </div>
                                </div>
                            </Block>
                        </div>
                        <div className='w-100 mt-2 profile-body py-3 px-3 pl-30'>
                            <MyShopInformation 
                                data={organization}
                                register={register}
                                reset={reset}
                                handleSubmit={handleSubmit}
                                getValues={getValues}
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                dirtyFields={dirtyFields}
                                watch={watch}
                                sendCards={(cards, cardOrder) => onSetCards(cards, cardOrder)}
                                trigger={trigger}
                            />
                            <Block
                                title='Users'
                                isOpen={usersToggle}
                                onToggle={() => {
                                    let usersToggleV = !usersToggle
                                    localStorage.setItem('usersToggle', usersToggleV)
                                    setUsersToggle(usersToggleV)
                                }}
                            >
                                <div className="">
                                    <ExpandableTable
                                        columns={columns}
                                        data={usersInOrg}
                                        toggleTxt="Inactive"
                                        getData={(offset) => {}}
                                        deleteRecord={(id) => {}}
                                        route="/#"
                                        refreshTable={() => {}}
                                        searchPlaceholder="Search user name, e-mail ..."
                                        isExpert={false}
                                        userFToogle={userFToogle}
                                        setUserFToogle={setUserFToogle}
                                        newUser={newUser}
                                        setNewUser={setNewUser}
                                        addNewUserInOrg={addNewUserInOrg}
                                        saveLoading={saveLoading}
                                    />
                                    
                                </div>
                            </Block>
                        </div>
                    </div>
                </div>
            </form>
        </Spin>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer.user,
        types: state.UserReducer.user_types.data
    }
}

const mapDispachToProps = (dispatch) => ({
    debouncedGetProfile: () => debouncedGetProfile(dispatch),
    debouncedUpdateProfile: (data) => debouncedUpdateProfile(dispatch,data),
})

export default connect(mapStateToProps, mapDispachToProps)(Profile)